<script setup lang="ts">
import { computed, defineProps, withDefaults } from 'vue';
import { PrimaryNavLink } from '@/json_schema_types/sidebar_navigation';
import { useTestHelpers } from '@/composables/useTestHelpers';

interface Props {
  link: PrimaryNavLink;
  collapsed?: boolean;
  compact?: boolean;
  theme?: 'light';
  highlighted?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  collapsed: false,
});
const { generateTestId } = useTestHelpers();
const faIconString = computed(() => {
  return props.highlighted ? `fa-solid ${props.link.icon}` : `fa-regular ${props.link.icon}`;
});
const toolTipContent = (el: HTMLElement) =>
  el.clientWidth < el.scrollWidth ? props.link.label : '';
</script>

<template>
  <li>
    <a
      class="navigation-link__link"
      :class="{
        'navigation-link__link--light': theme === 'light',
        'navigation-link__link--compact': compact,
        'navigation-link__link--highlighted': props.highlighted,
      }"
      :href="link.url"
      :data-test-id="generateTestId('tray-navigation-link', link.label)"
    >
      <FontAwesomeIcon
        v-if="link.icon"
        :icon="faIconString"
        :data-test-id="generateTestId('navigation button icon', link.label)"
        size="lg"
        class="fa-fw"
      />
      <span
        v-tippy="{
          content: toolTipContent,
          placement: 'right',
          delay: [500, 50],
        }"
        class="navigation-link__label"
        :class="{ 'visually-hidden': collapsed }"
      >
        {{ link.label }}
      </span>
    </a>
  </li>
</template>

<style lang="scss" scoped>
.navigation-link__link {
  display: flex;
  width: 100%;
  padding: var(--side-navigation-item-padding);
  gap: var(--side-navigation-icon-spacing);
  text-decoration: none;
  border-radius: 4px;
  align-items: center;

  &:hover {
    background-color: var(--navigation-hover-color);
  }

  &:link,
  &:visited,
  &:active {
    color: var(--navigation-text-color);
  }
}

.navigation-link__link--compact {
  padding: 0.5rem 1rem;
}

.navigation-link__link--light {
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: #000000;
  }
  &:hover,
  &:active {
    background-color: $platinum;
  }
}

.navigation-link__link--highlighted,
.navigation-link__link--highlighted:hover {
  background-color: var(--navigation-highlight-color);
  font-weight: bold;
}

.navigation-link__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
