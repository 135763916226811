<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import PopoverMenuItem from '@/components/atoms/Popover/PopoverMenuItem/Index.vue';
import PopoverMenuLink from '@/components/atoms/Popover/PopoverMenuItem/PopoverMenuLink.vue';
import PopoverMenuButton, {
  PopoverMenuButton as PopoverMenuButtonData,
} from '@/components/atoms/Popover/PopoverMenuItem/PopoverMenuButton.vue';
import { SubNavigationPopoverMenuItemType } from '@/types/components/organisms/SideNavigation';
import { BaseMenuLink, PrimaryNavLink } from '@/json_schema_types/sidebar_navigation';
import { useTestHelpers } from '@/composables/useTestHelpers';
import { ChevronColor } from '@/components/atoms/NavigationChevron/Index.vue';

interface SubNavigationPopoverMenuItemProps {
  item: BaseMenuLink | PrimaryNavLink | PopoverMenuButtonData;
  type: SubNavigationPopoverMenuItemType;
}

defineProps<SubNavigationPopoverMenuItemProps>();

const { generateTestId } = useTestHelpers();

const emit = defineEmits(['click']);
</script>

<template>
  <PopoverMenuItem
    :data-test-id="generateTestId('subnav-popover-menu-item', type)"
    padding="0.5rem 1.5rem"
  >
    <component
      :is="type === SubNavigationPopoverMenuItemType.Link ? PopoverMenuLink : PopoverMenuButton"
      :item="item"
      :custom-styles="{ color: 'var(--navigation-text-color)' }"
      :chevron-opts="{ theme: ChevronColor.BRANDED }"
      is-nav
      has-sub-menu
      @click="type === SubNavigationPopoverMenuItemType.Button ? emit('click') : null"
    />
  </PopoverMenuItem>
</template>
