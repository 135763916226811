<script lang="ts">
export default {
  name: 'ApplicationWrapper',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import {
  defineProps,
  onMounted,
  onBeforeUnmount,
  ref,
  Ref,
  watch,
  withDefaults,
  provide,
} from 'vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import {
  ISearchData,
  languageLinksKey,
  searchDataKey,
  whiteNavStyleToggleKey,
} from '@/types/InjectionKeys';
import SideNavigation from '@/components/organisms/SideNavigation/Index.vue';
import TopNavigation from '@/components/organisms/TopNavigationBar/Index.vue';
import MobileNavigationBar from '@/components/organisms/MobileNavigationBar/Index.vue';
import { useApiClient } from '@/composables/useApiClient';
import { ICurrentUser } from '@/types/Navigation';
import { SidebarNavigation } from '@/json_schema_types/sidebar_navigation';
import useSideNavCollapse, { SideNavStatus } from '@/composables/navigation/useSideNavCollapse';

const { apiClient } = useApiClient();
const breakpoints = useBreakpoints(breakpointsTailwind);
const smAndSmaller = breakpoints.smallerOrEqual('sm');
const largerThanSm = breakpoints.greater('sm');

type Props = {
  skipNavigation: boolean;
  navPath: string;
  rootPath: string;
  activeNav?: string;
  searchPath: string;
  contentDiscoverabilitySearch: boolean;
  companyName: string;
  logoUrl: string;
  customLogoUrl?: string;
  userSignedIn?: boolean;
  hideSideNavigation?: boolean;
  isWhiteNav?: boolean;
  currentUser: ICurrentUser;
  searchData: ISearchData;
  languageList?: string;
  addNewRemotePaths?: string[];
  canExpandSidebar: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  userSignedIn: false,
  isWhiteNav: false,
  customLogoUrl: '',
});

const navigationData: Ref<SidebarNavigation | undefined> = ref();

const { isSideNavCollapsed, toggleCollapse } = useSideNavCollapse({
  initialCollapsedState:
    (localStorage.getItem('side-navigation-status') as SideNavStatus) || SideNavStatus.COLLAPSED,
});

const fetchData = () => {
  if (props.skipNavigation) {
    return;
  }
  apiClient.get(props.navPath).then((response: INavResponse) => {
    navigationData.value = response.data;
  });
};

const setMainElementClasses = () => {
  const mainElement = document.getElementById('main-content');
  if (!mainElement) return;

  if (isSideNavCollapsed.value) {
    mainElement.classList.add('main-content--collapsed');
  } else {
    mainElement.classList.remove('main-content--collapsed');
  }
};

watch(
  () => isSideNavCollapsed.value,
  () => {
    setMainElementClasses();
  }
);

watch(
  () => props.userSignedIn,
  (newValue) => {
    if (newValue) {
      fetchData();
    }
  }
);

fetchData();

provide(searchDataKey, props.searchData);
if (props.languageList) {
  provide(languageLinksKey, JSON.parse(props.languageList));
}
provide(whiteNavStyleToggleKey, props.isWhiteNav);

let observer: MutationObserver;

onMounted(async () => {
  const onElementAvailable = (selector, callback) => {
    observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        callback();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
  };

  onElementAvailable('#main-content', () => {
    setMainElementClasses();
    $(document).trigger('vue-ready');
  });
});

onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect();
  }
});

interface INavResponse {
  data: SidebarNavigation;
}
</script>

<template>
  <!-- No navigation - just render the page content -->
  <template v-if="skipNavigation">
    <slot />
  </template>

  <template v-else-if="navigationData">
    <!-- Desktop navigation with content -->
    <template v-if="largerThanSm">
      <TopNavigation
        data-test-id="top-navigation"
        :can-search="!hideSideNavigation"
        :utility-nav-items="navigationData.utility_navigation_items"
        :add-new-items="navigationData.add_new_items"
        :add-new-remote-paths="addNewRemotePaths"
        :root-path="rootPath"
        :search-path="searchPath"
        :content-discoverability-search="contentDiscoverabilitySearch"
        :company-name="companyName"
        :logo-url="logoUrl"
        :custom-logo-url="customLogoUrl"
        :current-user="currentUser"
        :support-and-updates="navigationData.support_and_updates"
        :user-menu="navigationData.user_menu"
        :language-list="languageList"
        :is-side-nav-collapsed="isSideNavCollapsed || !canExpandSidebar"
      />
      <SideNavigation
        v-show="largerThanSm"
        :is-visible="!hideSideNavigation"
        :active-nav="activeNav"
        :primary-nav-items="navigationData?.primary_navigation_items"
        :settings="navigationData?.settings_menu"
        :contact-us-link="navigationData?.contact_us_link"
        :can-expand-sidebar="canExpandSidebar"
        :collapsed="isSideNavCollapsed || !canExpandSidebar"
        @collapse="toggleCollapse"
      >
        <slot />
      </SideNavigation>
    </template>

    <!-- Mobile navigation with content -->
    <MobileNavigationBar
      v-if="smAndSmaller"
      :primary-nav-items="navigationData.primary_navigation_items"
      :utility-nav-items="navigationData.utility_navigation_items"
      :add-new-items="navigationData.add_new_items"
      :user-menu="navigationData.user_menu"
      :current-user="currentUser"
      :logo-url="logoUrl"
      :root-path="rootPath"
      :search-path="searchPath"
      :content-discoverability-search="contentDiscoverabilitySearch"
      :company-name="companyName"
    >
      <slot />
    </MobileNavigationBar>
  </template>
</template>

<style lang="scss" scoped>
.application-wrapper {
  color: inherit;
}
</style>
