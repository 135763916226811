<script lang="ts">
export default {
  name: 'TopNavigationBar',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, onMounted, ref } from 'vue';
import AddNew from '@/components/molecules/AddNew/Index.vue';
import NavigationHeaderItem from '@/components/atoms/NavigationHeaderItem/Index.vue';
import UserAccountMenu from '@/components/molecules/UserAccountMenu/Index.vue';
import AnnouncementsSidebar from '@/components/organisms/AnnouncementsSidebar/Index.vue';
import QuickSearchInput from '@/components/molecules/QuickSearchInput/Index.vue';
import QuickSearchResults from '@/components/molecules/QuickSearchResults/Index.vue';
import NotificationsDropdown from '@/components/molecules/NotificationsDropdown/Index.vue';
import { useTestHelpers } from '@/composables/useTestHelpers';
import { useI18n } from '@/composables/useI18n';
import { breakpointsTailwind, onClickOutside, useBreakpoints } from '@vueuse/core';
import { ICurrentUser } from '@/types/Navigation';
import {
  AddNewLinkGroup,
  SupportAndUpdates,
  UserMenu,
  UtilityNavItems,
} from '@/json_schema_types/sidebar_navigation';
import { WhiteNavStyleToggle, whiteNavStyleToggleKey } from '@/types/InjectionKeys';
import { inject } from 'vue';

interface Props {
  rootPath: string;
  searchPath: string;
  contentDiscoverabilitySearch: boolean;
  companyName: string;
  logoUrl: string;
  customLogoUrl?: string;
  utilityNavItems: UtilityNavItems;
  addNewItems?: AddNewLinkGroup[];
  addNewRemotePaths?: string[];
  currentUser: ICurrentUser;
  userMenu?: UserMenu;
  supportAndUpdates?: SupportAndUpdates;
  isSideNavCollapsed: boolean;
  canSearch?: boolean;
}

defineProps<Props>();

const { generateTestId } = useTestHelpers();
const { t } = useI18n();

const breakpoints = useBreakpoints(breakpointsTailwind);
const smallerThanLg = breakpoints.smaller('lg');

const searchTerm = ref('');
const searchInput = ref(null);
const searchResults = ref(null);
const searchResultsOpen = ref(false);

const isWhiteNav: WhiteNavStyleToggle | undefined = inject(whiteNavStyleToggleKey);

const showSearchResults = () => {
  searchResultsOpen.value = true;
};

onMounted(() => {
  onClickOutside(searchResults, hideSearchResults, { ignore: [searchInput] });
});

const hideSearchResults = () => {
  searchResultsOpen.value = false;
};
</script>

<template>
  <header
    class="tw-flex tw-w-full tw-fixed tw-top-0 tw-items-center tw-justify-between"
    :class="[
      'top-navigation-bar',
      isSideNavCollapsed ? 'tw-px-5' : 'tw-px-4',
      {
        'top-navigation-bar--white': isWhiteNav,
        'top-navigation-bar--white-expanded': isWhiteNav && !isSideNavCollapsed,
      },
    ]"
  >
    <a
      :href="rootPath"
      :class="[
        'top-navigation-bar__company-logo tw-flex tw-justify-center tw-mr-5',
        { 'top-navigation-bar__company-logo--expanded': !isSideNavCollapsed },
      ]"
    >
      <Transition name="fade" mode="out-in">
        <img
          v-if="!isSideNavCollapsed && customLogoUrl"
          key="customLogo"
          :src="customLogoUrl"
          :alt="companyName"
          class="top-navigation-bar__company-custom-logo-image"
        />
        <img
          v-else
          key="defaultLogo"
          :src="logoUrl"
          :alt="companyName"
          class="top-navigation-bar__company-logo-image"
        />
      </Transition>
    </a>

    <div v-if="canSearch" ref="searchInput" class="top-navigation-bar__quick-search">
      <QuickSearchInput
        v-model="searchTerm"
        type="slim"
        :bordered="false"
        :search-path="searchPath"
        :content-discoverability-search="contentDiscoverabilitySearch"
        @focus="showSearchResults"
      >
        <div v-show="searchResultsOpen" class="top-navigation-bar__quick-search-results-container">
          <QuickSearchResults ref="searchResults" v-model="searchTerm" />
        </div>
      </QuickSearchInput>
    </div>

    <div class="top-navigation-bar__spacer"></div>

    <div
      v-if="utilityNavItems"
      class="tw-flex tw-items-center tw-justify-between"
      :class="smallerThanLg ? 'tw-gap-2' : 'tw-gap-4'"
    >
      <company-switcher
        v-if="utilityNavItems.company_switcher"
        :search-endpoint="utilityNavItems.company_switcher.search_endpoint"
        :admin="utilityNavItems.company_switcher.admin"
        :default-error-msg="t('errors.internal_server_error.something_went_wrong')"
        menu-direction="right"
      />

      <AddNew :add-new-items="addNewItems" :add-new-remote-paths="addNewRemotePaths" />

      <NavigationHeaderItem
        v-if="supportAndUpdates"
        id="announcements-toggle"
        :icon="supportAndUpdates.icon"
        :aria-label="supportAndUpdates.label"
        :tool-tip-text="supportAndUpdates.label"
        :data-test-id="generateTestId('top navigation bar', supportAndUpdates.label.toLowerCase())"
      />

      <NavigationHeaderItem
        v-if="utilityNavItems.beta_features"
        :icon="utilityNavItems.beta_features.icon"
        :href="utilityNavItems.beta_features.url"
        :tool-tip-text="utilityNavItems.beta_features.label"
        :aria-label="utilityNavItems.beta_features.label"
        :badge-count="utilityNavItems.beta_features.count"
        :data-test-id="
          generateTestId('top navigation bar', utilityNavItems.beta_features.label.toLowerCase())
        "
      />

      <NavigationHeaderItem
        v-if="utilityNavItems.inboxes"
        :icon="utilityNavItems.inboxes.icon"
        :href="utilityNavItems.inboxes.url"
        :tool-tip-text="utilityNavItems.inboxes.label"
        :aria-label="utilityNavItems.inboxes.label"
        :badge-count="utilityNavItems.inboxes.count"
        :data-test-id="
          generateTestId('top navigation bar', utilityNavItems.inboxes.label.toLowerCase())
        "
      />

      <NavigationHeaderItem
        v-if="utilityNavItems.bookmarks"
        :icon="utilityNavItems.bookmarks.icon"
        :href="utilityNavItems.bookmarks.url"
        :tool-tip-text="utilityNavItems.bookmarks.label"
        role="button"
        tabindex="0"
        :aria-label="utilityNavItems.bookmarks.label"
        :badge-count="utilityNavItems.bookmarks.count"
        :data-test-id="
          generateTestId('top navigation bar', utilityNavItems.bookmarks.label.toLowerCase())
        "
      />

      <NotificationsDropdown
        v-if="utilityNavItems.notifications"
        :utility-nav-items="utilityNavItems"
      />

      <UserAccountMenu
        v-if="userMenu"
        :user-menu="userMenu"
        :current-user="currentUser"
        :company="{ name: companyName, logo: logoUrl }"
        :data-test-id="generateTestId('top navigation bar', 'user account menu')"
      />

      <AnnouncementsSidebar
        v-if="supportAndUpdates"
        :support-only="supportAndUpdates.support_only"
        :admin-owner-user="supportAndUpdates.admin_owner_user"
        :chat-bot-url="supportAndUpdates.chatbot_url"
        :announcements-endpoint="supportAndUpdates.announcements_endpoint"
        :webinars-endpoint="supportAndUpdates.webinars_endpoint"
        :endpoint="supportAndUpdates.endpoint"
      />
    </div>
  </header>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.top-navigation-bar {
  min-height: var(--navigation-height);
  color: var(--navigation-text-color);
  background-color: var(--navigation-color);
  z-index: 1030;

  &__company-logo {
    width: calc(var(--side-navigation-width) - 2.4rem);

    &--expanded {
      width: calc(var(--side-navigation-width-expanded-dynamic) - 2rem);
      max-width: calc(var(--side-navigation-width-expanded-dynamic) * 2 - 2rem);
    }
  }

  //Temp fixes for company switcher
  :deep(.hidden-nav-switcher) {
    display: flex;
  }
  :deep(.hidden-nav-switcher .navbar__link--dropdown) {
    color: var(--navigation-text-color);
    font-size: inherit;
    padding: 1rem !important;
    color: var(--navigation-text-color);
    border-radius: 4px;
    display: flex;
    align-items: center;

    &:hover,
    &:focus,
    &:active {
      background-color: var(--navigation-hover-color);
    }
  }
}

.top-navigation-bar--white {
  &::after {
    content: '';
    position: absolute;
    top: calc(var(--navigation-height) - 10px);
    left: calc(var(--side-navigation-width) + 5px);
    min-width: 100%;
    height: 1px;
    background-color: transparent;
    box-shadow: 4.5px 10.4px 2px rgba(0, 0, 0, 0.8);
  }
}

.top-navigation-bar--white-expanded {
  &::after {
    left: calc(var(--side-navigation-width-expanded-dynamic) + 5px);
  }
}

.top-navigation-bar__company-logo-image {
  height: 35px;
  width: calc(var(--side-navigation-width) - 2.4rem);
  border-radius: 100%;
}

.top-navigation-bar__company-custom-logo-image {
  height: 4.2rem;
  max-width: 100%;
  border-radius: 10px;
}

.top-navigation-bar__spacer {
  display: flex;
  flex: 1;
}

.top-navigation-bar__quick-search {
  position: relative;
  display: flex;
  flex: 1;
  flex-basis: max-content;
  margin-right: auto;
}

.top-navigation-bar__quick-search-results-container {
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  max-width: 500px;
  min-width: 300px;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  max-height: calc(100vh - var(--navigation-height));
  overflow: hidden;
  display: flex;
}
</style>
