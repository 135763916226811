<script lang="ts">
export default {
  name: 'QuickSearchResults',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};

interface IRecentSearchesResponse {
  data: {
    searches: [];
  };
}

interface IRecentSearch {
  id: number;
  term: string;
  delete_path: string;
}
</script>

<script setup lang="ts">
import { computed, defineProps, inject, ref, Ref, watch } from 'vue';
import LoadingSpinner from '@/components/atoms/LoadingSpinner/Index.vue';
import RecentSearchResult from '@/components/molecules/QuickSearchResults/RecentSearchResult.vue';
import QuickSearchResult, {
  IQuickSearchResults,
} from '@/components/molecules/QuickSearchResults/QuickSearchResult.vue';
import { useApiClient } from '@/composables/useApiClient';
import { useI18n } from '@/composables/useI18n';
import { ISearchData, searchDataKey } from '@/types/InjectionKeys';
import { useElementVisibility, watchDebounced } from '@vueuse/core';

const quickSearchResults = ref(null);
const targetIsVisible = useElementVisibility(quickSearchResults);

const { t } = useI18n();
const { apiClient } = useApiClient();

const props = defineProps<{ modelValue: string }>();
const localSearchTerm = ref('');

const searchResults: Ref<IQuickSearchResults[] | undefined> = ref();
const loading = ref(true);
const error = ref(false);
const recentSearches: Ref<IRecentSearch[]> = ref([]);
const searchData: ISearchData | undefined = inject(searchDataKey);

const visibleRecentSearches = computed(() => {
  return recentSearches.value.slice(0, 5).map((recentSearch) => ({
    ...recentSearch,
    ariaLabel: t('vue_templates.quick_search.aria_recent_search_link', {
      search_term: recentSearch.term,
    }),
    ariaDeleteLabel: t('vue_templates.quick_search.aria_recent_search_remove', {
      search_term: recentSearch.term,
    }),
  }));
});

const fetchRecentSearches = () => {
  if (!searchData) {
    throw new Error('Unable to fetch recent searches');
  }

  apiClient.get(searchData.recentSearchesPath).then((response: IRecentSearchesResponse) => {
    recentSearches.value = response.data.searches;
    loading.value = false;
  });
};

window.emitter.on('update:recentSearches', (res) =>
  console.log('TODO - update recent searches', res)
);

const deleteRecentSearch = (recentSearch) => {
  if (recentSearch && recentSearch.delete_path) {
    apiClient.delete(recentSearch.delete_path);
    recentSearches.value = recentSearches.value.filter(
      (search) => search.term !== recentSearch.term
    );
  }
};

const fetchSearchResults = (newSearchVal) => {
  if (!searchData) {
    throw new Error('Unable to fetch search results');
  }

  apiClient
    .get(searchData.searchesPath, { params: { search: newSearchVal } })
    .then(
      (response) => {
        searchResults.value = transformedSearchResults(response.data);
        window.emitter.emit('update:quickSearchResults', searchResults.value);
      },
      () => {
        error.value = true;
      }
    )
    .finally(() => {
      loading.value = false;
    });
};

const transformedSearchResults = (response) => {
  return [
    {
      type: 'item',
      entity: 'Item',
      values: response.items,
      path: response.view_items_path,
      total: response.total_items,
    },
    {
      type: 'learnlists',
      entity: 'Learnlist',
      values: response.learnlists,
      path: response.view_learnlists_path,
      total: response.total_learnlists,
    },
    {
      type: 'channels',
      entity: 'Channel',
      values: response.carousels,
      path: response.view_carousels_path,
      total: response.total_carousels,
    },
    {
      type: 'categories',
      entity: 'Category',
      values: response.categories,
      path: response.view_categories_path,
      total: response.total_categories,
    },
    {
      type: 'quizzes',
      entity: 'Quiz',
      values: response.quizzes,
      path: response.view_quizzes_path,
      total: response.total_quizzes,
    },
    {
      type: 'people',
      entity: 'User',
      values: response.users,
      path: response.view_users_path,
      total: response.total_users,
    },
    {
      type: 'teams',
      entity: 'Team',
      values: response.teams,
      path: response.view_teams_path,
      total: response.total_teams,
    },
    {
      type: 'events',
      entity: 'Event',
      values: response.events,
      path: response.view_events_path,
      total: response.total_events,
    },
  ] as IQuickSearchResults[];
};

const hasAnyResults = computed(() => {
  return searchResults.value?.some((result) => result.values.length > 0);
});

const saveSearch = () => {
  if (!searchData) {
    throw new Error('Unable to save search');
  }
  apiClient.post(searchData.searchesPath, { search: { term: props.modelValue } });
};

watch(
  () => props.modelValue,
  () => {
    loading.value = true;
  }
);

watchDebounced(
  () => props.modelValue,
  (newSearchVal) => {
    if (newSearchVal === '') {
      loading.value = false;
      return;
    }

    fetchSearchResults(newSearchVal);
  },
  { debounce: 500, maxWait: 3000 }
);

watch(
  () => targetIsVisible.value,
  (isVisible) => {
    if (isVisible) {
      if (recentSearches.value.length > 0) {
        return;
      }

      fetchRecentSearches();
    }
  }
);
</script>

<template>
  <div ref="quickSearchResults" class="quick-search-results">
    <div v-if="loading" class="quick-search-results__loading">
      <LoadingSpinner data-test-id="quick-search-results-loading" type="small" />
    </div>

    <div v-else-if="modelValue !== ''" class="quick-search-results__list">
      <template v-if="hasAnyResults">
        <template v-for="result in searchResults" :key="result.type">
          <QuickSearchResult v-if="result.total > 0" :result="result" @save-search="saveSearch" />
        </template>
      </template>
      <template v-else-if="error">
        {{ t('vue_templates.quick_search.error') }}
      </template>
      <span v-else class="p-t-1">
        {{ t('vue_templates.quick_search.search_results_empty', { search_term: modelValue }) }}
      </span>
    </div>

    <template v-else>
      <span class="quick-search-results__heading">
        {{ t('vue_templates.quick_search.recent_searches') }}
      </span>
      <span v-if="recentSearches && recentSearches?.length === 0" class="p-x-1 p-t-2">
        {{ t('vue_templates.quick_search.recent_searches_empty') }}
      </span>
      <ul v-else class="quick-search-results__recent-search-list">
        <RecentSearchResult
          v-for="recentSearch in visibleRecentSearches"
          :key="recentSearch.id"
          :recent-search="recentSearch"
          :model-value="localSearchTerm"
          @update:model-value="(event) => $emit('update:modelValue', event)"
          @delete-recent-search="deleteRecentSearch(recentSearch)"
        />
      </ul>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.quick-search-results {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #333333;
  padding: 1rem;
}

.quick-search-results__heading {
  color: $royal-blue;
  font-size: 1.5rem;
  padding: 0 1rem;
  font-weight: 600;
}

.quick-search-results__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.quick-search-results__list {
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 1rem;
  overflow-y: scroll;
}

ul,
li {
  all: unset;
}
</style>
