<script setup lang="ts">
import { computed, defineProps, ref } from 'vue';
import { PrimaryNavLink } from '@/json_schema_types/sidebar_navigation';
import { useTestHelpers } from '@/composables/useTestHelpers';
import SubNavigationPopover from './SubNavigationPopover.vue';

interface Props {
  link: PrimaryNavLink;
  collapsed: boolean;
  highlighted?: boolean; // For active state usage
  focused?: boolean; // For sub tray focusing
}

const props = defineProps<Props>();
const { generateTestId } = useTestHelpers();
const faIconString = computed(() => {
  return props.highlighted ? `fa-solid ${props.link.icon}` : `fa-regular ${props.link.icon}`;
});

const isPopoverOpen = ref(false);
const popoverTopOffset = ref<string | null>(null);
const popoverMaxHeight = ref<string>('350px');

const navigationButtonRef = ref<HTMLElement | null>(null);

const calculateTopOffset = (rectTop: number) => {
  if (!rectTop) return null;

  return `calc(${rectTop}px - 6rem)`;
};

const calculatePopoverMaxHeight = (rectBottom: number) => {
  if (!rectBottom) return '350px';

  return `calc(100vh - ${rectBottom}px - 1rem)`;
};

const togglePopover = () => {
  if (navigationButtonRef.value && !isPopoverOpen.value) {
    const rect = navigationButtonRef.value.getBoundingClientRect();
    popoverTopOffset.value = calculateTopOffset(rect.top);
    popoverMaxHeight.value = calculatePopoverMaxHeight(rect.bottom);
  }

  isPopoverOpen.value = !isPopoverOpen.value;
};
</script>

<template>
  <li>
    <button
      ref="navigationButtonRef"
      :class="[
        'navigation-button__button',
        { 'navigation-button__button--highlighted': props.highlighted },
        {
          'navigation-button__button--focused':
            (props.focused || isPopoverOpen) && !props.highlighted,
        },
      ]"
      :data-test-id="generateTestId('side-navigation-link', link.label)"
      @click="togglePopover"
    >
      <FontAwesomeIcon
        v-if="link.icon"
        :icon="faIconString"
        :data-test-id="generateTestId('navigation button icon', link.label)"
        size="lg"
        class="fa-fw"
      />
      <span class="navigation-button__label" :class="{ 'visually-hidden': collapsed }">{{
        link.label
      }}</span>
    </button>
    <Teleport defer to="#subnavs-popover">
      <SubNavigationPopover
        v-model="isPopoverOpen"
        :nav-item="link"
        :custom-styles="{
          borderRadius: '1rem',
          left: collapsed
            ? 'calc(var(--side-navigation-width) + 1rem)'
            : 'calc(var(--side-navigation-width-expanded-dynamic) + 1rem)',
          ...(popoverTopOffset && { top: popoverTopOffset }),
        }"
        :max-height="popoverMaxHeight"
        :toggle-element-selector="navigationButtonRef"
      />
    </Teleport>
  </li>
</template>

<style scoped lang="scss">
.navigation-button__button {
  all: unset;
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: var(--side-navigation-item-padding);
  gap: var(--side-navigation-icon-spacing);
  text-wrap: nowrap;
  border-radius: 4px;
  align-items: center;

  &:hover,
  &--focused {
    background-color: var(--navigation-hover-color);
  }

  &--highlighted,
  &--highlighted:hover {
    background-color: var(--navigation-highlight-color);
    font-weight: bold;
  }

  .navigation-button__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
