import {
  PrimaryNavLink,
  PrimaryNavLinkGroup,
  SettingsMenu,
  BaseMenuLink,
} from '@/json_schema_types/sidebar_navigation';
import { ref } from 'vue';

interface IUseActiveNavProps {
  navs: {
    primaryNavItems: PrimaryNavLinkGroup[];
    settingsNavItems?: SettingsMenu;
    contactUsLink?: BaseMenuLink;
  };
  activeNav?: string;
}

const generateNavKeys = (navs: {
  primaryNavItems: PrimaryNavLinkGroup[];
  settingsNavItems?: SettingsMenu;
  contactUsLink?: BaseMenuLink;
}) => {
  const { primaryNavItems, settingsNavItems, contactUsLink } = navs;
  if (!primaryNavItems?.length) return {};

  const navMap: Record<string, string> = {};

  // Recursive function for link children
  const processLinks = (links: PrimaryNavLink[], tag?: string) => {
    links.forEach((link) => {
      const linkTag = tag || link.tag;

      // If no tag was supplied at all, we skip since the tag is what determines which nav is active
      if (!linkTag) return;
      if (link.url) {
        navMap[link.url] = linkTag;
        return;
      }
      if (link.children) {
        link.children.forEach((childGroup) => processLinks(childGroup.links, linkTag));
      }
    });
  };

  // Primary navs
  primaryNavItems.forEach((navGroup) => processLinks(navGroup.links));

  // Settings navs
  if (settingsNavItems?.children) {
    settingsNavItems.children.forEach((settingsNav) => {
      if (settingsNav.url && settingsNavItems.tag) {
        navMap[settingsNav.url] = settingsNavItems.tag;
      }
    });
  }

  // Contact Us link
  if (contactUsLink?.url && contactUsLink?.tag) {
    navMap[contactUsLink.url] = contactUsLink.tag;
  }
  return navMap;
};

export default function useActiveNav({
  navs: { primaryNavItems, settingsNavItems, contactUsLink },
  activeNav,
}: IUseActiveNavProps) {
  const NAV_MAP = ref(generateNavKeys({ primaryNavItems, settingsNavItems, contactUsLink }));

  const isNavActive = (navLink: PrimaryNavLink | SettingsMenu | BaseMenuLink): boolean => {
    if (!activeNav) return false;
    if (navLink.tag === activeNav) return true;

    if (NAV_MAP.value[activeNav] === navLink.tag) return true;

    return false;
  };

  return { isNavActive };
}
