import { ref, Ref } from 'vue';

interface IUseSideNavCollapseProps {
  initialCollapsedState: SideNavStatus.COLLAPSED | SideNavStatus.EXPANDED;
}

export default function useSideNavCollapse(props: IUseSideNavCollapseProps) {
  const isSideNavCollapsed: Ref<boolean> = ref(
    props.initialCollapsedState === SideNavStatus.COLLAPSED
  );

  const toggleCollapse = () => {
    isSideNavCollapsed.value = !isSideNavCollapsed.value;

    localStorage.setItem(
      'side-navigation-status',
      isSideNavCollapsed.value ? SideNavStatus.COLLAPSED : SideNavStatus.EXPANDED
    );
  };

  return { isSideNavCollapsed, toggleCollapse };
}

export enum SideNavStatus {
  COLLAPSED = 'collapsed',
  EXPANDED = 'expanded',
}
