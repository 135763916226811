<script lang="ts">
export default {
  name: 'SideNavigation',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, ref, withDefaults, watch, nextTick } from 'vue';
import { useI18n } from '@/composables/useI18n';
import {
  PrimaryNavLinkGroup,
  SettingsMenu as ISettingsMenu,
  BaseMenuLink,
  PrimaryNavLink,
} from '@/json_schema_types/sidebar_navigation';
import NavigationButton from './NavigationButton.vue';
import NavigationLink from './NavigationLink.vue';
import SettingsMenuComponent from '@/components/molecules/SettingsMenu/Index.vue';
/*
  [LA-23809] Commented out for implementation of popover subnav
  Potentially still usable; Completely remove when applicable
*/
// import SubNavigation from './SubNavigation.vue';
import useActiveNav from '@/composables/navigation/useActiveNav';
import useSideNavDynamicWidth from '@/composables/navigation/useSideNavDynamicWidth';
import { useTestHelpers } from '@/composables/useTestHelpers';
import { WhiteNavStyleToggle, whiteNavStyleToggleKey } from '@/types/InjectionKeys';
import { inject } from 'vue';

const { t } = useI18n();

type Props = {
  isVisible: boolean;
  activeNav?: string;
  settings?: ISettingsMenu;
  contactUsLink?: BaseMenuLink;
  primaryNavItems: PrimaryNavLinkGroup[];
  collapsed: boolean;
  canExpandSidebar: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  isVisible: false,
});

const expandedNav = ref<PrimaryNavLink['tag']>(undefined);
const subNavItems = ref();
const isSubNavActive = ref(false);

const isWhiteNav: WhiteNavStyleToggle | undefined = inject(whiteNavStyleToggleKey);

const { setDynamicWidth } = useSideNavDynamicWidth();

const { isNavActive } = useActiveNav({
  navs: {
    primaryNavItems: props.primaryNavItems,
    settingsNavItems: props.settings,
    contactUsLink: props.contactUsLink,
  },
  activeNav: props.activeNav,
});

const { generateTestId } = useTestHelpers();

const toggleSubNavigation = (navLink: PrimaryNavLink) => {
  if (navLink.tag === expandedNav.value) {
    isSubNavActive.value = !isSubNavActive.value;
    return;
  }

  expandedNav.value = navLink.tag;
  subNavItems.value = navLink.children;
  isSubNavActive.value = true;

  nextTick(() => {
    const subNavElement = document.getElementById('main-sub-navigation');
    subNavElement?.focus();
  });
};

const isSubNavFocused = (link: PrimaryNavLink) => {
  if (!isSubNavActive.value) return false;

  return link.children && link.tag === expandedNav.value;
};

/*
  [LA-23809] Commented out for implementation of popover subnav
  Potentially still usable; Completely remove when applicable
*/
// const onSubnavClose = () => {
//   if (isSubNavActive.value) {
//     expandedNav.value = undefined;
//     isSubNavActive.value = false;
//     subNavItems.value = [];

//     const navElement = document.getElementById('main-navigation');
//     navElement?.focus();
//   }
// };

watch(
  () => props.collapsed,
  () => {
    if (!props.collapsed) {
      nextTick(() => {
        setDynamicWidth();
      });
    }
  },
  { immediate: true }
);
</script>

<template>
  <div class="main-content-container">
    <aside
      v-if="isVisible"
      class="side-navigation side-navigation--loaded"
      :class="{
        'side-navigation--expanded': !collapsed,
        'side-navigation--sub-nav-active': isSubNavActive && !isWhiteNav,
        'side-navigation--white': isWhiteNav,
      }"
    >
      <nav
        id="main-navigation"
        class="side-navigation__navigation"
        :aria-label="t('vue_templates.side_navigation.aria_label')"
        tabindex="0"
      >
        <div
          class="side-navigation__primary-navigation-groups"
          data-test-id="side-navigation-primary-navigation-groups"
        >
          <ul
            v-for="(navGroup, navGroupIndex) in primaryNavItems"
            :key="`nav-group-${navGroupIndex}`"
            class="side-navigation__list"
            :data-test-id="generateTestId('side-navigation-group', navGroup.group_title)"
          >
            <component
              :is="link.children ? NavigationButton : NavigationLink"
              v-for="(link, linkIndex) in navGroup.links"
              :id="navGroupIndex"
              :key="`side-navigation-item-${linkIndex}`"
              v-tippy="{ content: collapsed ? link.label : null }"
              :data-test-id="generateTestId('side-navigation-item', `${linkIndex}`)"
              :link="link"
              class="side-navigation__navigation-item"
              :collapsed="collapsed"
              :highlighted="isNavActive(link)"
              :focused="isSubNavFocused(link)"
              :aria-expanded="link.children && link.tag === expandedNav"
              :aria-controls="link.children ? '#main-sub-navigation' : null"
              @open-sub-nav="link.children ? toggleSubNavigation(link) : null"
            />
          </ul>
        </div>
        <ul class="side-navigation__list side-navigation__list--spacer">
          <SettingsMenuComponent
            v-if="settings"
            :settings="settings"
            :collapsed="collapsed"
            :highlighted="isNavActive(settings)"
            class="side-navigation__navigation-item"
            data-test-id="side-navigation-settings"
            @menu-opened="isSubNavActive = false"
          />
          <NavigationLink
            v-if="contactUsLink"
            v-tippy="{
              content: collapsed ? contactUsLink.label : null,
              placement: 'right',
              delay: [1000, 50],
            }"
            :data-test-id="`contact-us-link`"
            :link="contactUsLink"
            class="side-navigation__navigation-item"
            :collapsed="collapsed"
            :highlighted="isNavActive(contactUsLink)"
          />
        </ul>

        <ul v-if="canExpandSidebar" class="side-navigation__list">
          <li class="side-navigation__navigation-item">
            <button
              class="side-navigation__collapse-button"
              :aria-describedby="collapsed ? 'expand-sidebar' : 'collapse-sidebar'"
              data-test-id="collapse-expand-sidenav-button"
              @click="$emit('collapse')"
            >
              <FontAwesomeIcon
                :icon="collapsed ? 'fa-arrow-right-to-line' : 'fa-arrow-left-to-line'"
                data-test-id="collapse-sidenav-icon"
                size="lg"
                class="fa-fw"
              />
              <span v-if="!collapsed" aria-hidden="true">
                {{ t('vue_templates.side_navigation.collapse') }}
              </span>
            </button>
            <span id="expand-sidebar" class="visually-hidden" aria-hidden="true">
              {{ t('vue_templates.side_navigation.expand_aria_label') }}
            </span>
            <span id="collapse-sidebar" class="visually-hidden" aria-hidden="true">
              {{ t('vue_templates.side_navigation.collapse') }}
            </span>
          </li>
        </ul>
      </nav>
    </aside>
    <div id="subnavs-popover"></div>
    <!--
      [LA-23809] Commented out for implementation of popover subnav
      Potentially still usable; Completely remove when applicable
    -->
    <!-- <SubNavigation
      id="main-sub-navigation"
      v-model="isSubNavActive"
      data-test-id="main-sub-navigation"
      tabindex="0"
      :aria-label="`${subNavItems?.[0]?.group_title || ''} Sub navigation`"
      :side-bar-collapsed="collapsed"
      :nav-items="subNavItems"
      @close="onSubnavClose"
      @keydown.esc="onSubnavClose"
    /> -->
    <slot />
  </div>
</template>

<style lang="scss">
.main-content-container {
  position: fixed;
  height: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  top: var(--navigation-height);
  left: 0;

  #main-content {
    height: calc(100% - var(--navigation-height));
    flex: 1;
    overflow: scroll;
  }
}

.side-navigation {
  background-color: var(--navigation-color);
  color: var(--navigation-text-color);
  width: var(--side-navigation-width);
  overflow: hidden;
  transition: width 0.3s ease-in;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.8);
  z-index: var(--side-navigation-z-index);

  &::after {
    content: '';
    transition: left 0.3s ease-in;
    position: absolute;
    background-color: transparent;
    left: var(--side-navigation-width);
    height: 25px;
    width: 10px;
    border-top-left-radius: 12px;
    box-shadow: 0 -10px 0 0 var(--navigation-color);
    transition: width 0.3s ease-in;
  }
}

.side-navigation--white {
  &::after {
    border-top: 2.4px solid rgba(0, 0, 0, 0.23);
  }
}

.side-navigation--loaded {
  display: flex;
  max-height: calc(100vh - var(--navigation-height));
}

.side-navigation--expanded {
  min-width: var(--side-navigation-width-expanded);
  max-width: calc(var(--side-navigation-width-expanded) * 2);
  width: auto;

  &::after {
    left: var(--side-navigation-width-expanded-dynamic);
  }
}

.side-navigation--sub-nav-active {
  box-shadow: none;
}

.side-navigation__navigation {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;

  .navigation-link__label,
  .navigation-button__label {
    font-weight: 500;
    transition: opacity 0.6s ease-in-out;
    opacity: 1;

    &.visually-hidden {
      opacity: 0 !important;
    }
  }
}

%divider {
  position: absolute;
  content: '';
  border-bottom: 1px solid var(--navigation-text-color);
  width: 85%;
  top: 0;
  left: 50%;
  transform: translateX(-50%) scaleY(0.5);
  transform-origin: bottom;
}

.side-navigation__list {
  position: relative;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
  margin: 0 0 1rem;
  list-style-type: none;
  flex-basis: 0;
  &:not(:first-of-type) {
    padding: 1rem 0 0;
  }
  &:not(:first-of-type):before {
    @extend %divider;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.side-navigation__list--spacer {
  margin-top: auto;
  &:not(:first-of-type):before {
    content: none;
  }
}

.side-navigation__list--add-new {
  flex: 0;
  margin-bottom: 2rem;
}

.side-navigation__primary-navigation-groups {
  display: flex;
  flex-direction: column;
}

.side-navigation__navigation-item {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem 1rem;
}

.side-navigation__collapse-button,
.side-navigation__settings-button {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--side-navigation-icon-spacing);
  padding: var(--side-navigation-item-padding);
  border-radius: 4px;
  width: 100%;
  font-weight: 500;
}

.side-navigation__collapse-button:hover {
  background-color: var(--navigation-hover-color);
}
</style>
