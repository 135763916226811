<script lang="ts">
export default {
  name: 'PopoverMenuButton',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, withDefaults } from 'vue';
import Chevron, {
  ChevronColor,
  ChevronDirection,
  NavigationChevronProps as ChevronOpts,
  ChevronSize,
} from '@/components/atoms/NavigationChevron/Index.vue';

export interface PopoverMenuButton {
  icon?: string;
  label: string;
}

interface Props {
  item: PopoverMenuButton;
  customStyles?: Record<string, string>;
  hasSubMenu?: boolean;
  active?: boolean;
  chevronOpts?: ChevronOpts;
  isNav?: boolean;
}

withDefaults(defineProps<Props>(), {
  chevronOpts: () => ({ direction: ChevronDirection.RIGHT, theme: ChevronColor.LIGHT }),
  isNav: false,
});
</script>

<template>
  <button
    class="popover-menu-item__button"
    :class="{ active, 'popover-menu-item__button--nav': isNav }"
    :style="customStyles"
  >
    <FontAwesomeIcon v-if="item.icon" :icon="`${item.icon} fa-regular`" size="lg" class="fa-fw" />
    <span class="popover-menu-item__button-text">{{ item.label }}</span>
    <Chevron v-if="hasSubMenu" v-bind="chevronOpts" :size="ChevronSize.SMALL" />
  </button>
</template>

<style lang="scss" scoped>
.popover-menu-item__button {
  all: unset;
  color: $black;
  display: flex;
  flex: 1;
  width: 100%;
  gap: 1rem;
  align-items: center;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  user-select: none;

  &.active,
  &:hover {
    background-color: $platinum;
  }

  .popover-menu-item__button-text {
    flex: 1;
  }

  &--nav:hover {
    background-color: var(--navigation-hover-color);
  }
}
</style>
