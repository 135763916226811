<script lang="ts">
export default {
  name: 'QuickSearchResult',
  compatConfig: { MODE: 3 },
};
type ResultType =
  | 'channels'
  | 'categories'
  | 'people'
  | 'item'
  | 'quizzes'
  | 'teams'
  | 'learnlists'
  | 'events';

interface IQuickSearchResultValue {
  id: number;
  name: string;
  url: string;
  image_url?: string;
  type_icon?: string;
  supplier_name?: string;
  visibility_icon?: string;
}
</script>

<script setup lang="ts">
import { defineEmits, defineProps } from 'vue';
import { useI18n } from '@/composables/useI18n';

export interface IQuickSearchResults {
  type: ResultType;
  // TODO: Make a proper type for entity
  entity: string;
  total: number;
  values: IQuickSearchResultValue[];
  path: string;
}

const { t } = useI18n();
defineProps<{ result: IQuickSearchResults }>();
const emit = defineEmits(['saveSearch']);
const saveSearch = (url: string) => {
  emit('saveSearch');
  window.location.href = url;
};
</script>

<template>
  <section>
    <span class="quick-search-results__heading">{{
      t(`vue_templates.quick_search.${result.type}_heading`)
    }}</span>
    <ul>
      <li v-for="value in result.values" :key="value.id">
        <a
          v-if="value.url"
          :href="value.url"
          :title="value.name"
          class="quick-search-results__link"
          @click.prevent="saveSearch(value.url)"
          @keyup.prevent.enter="saveSearch(value.url)"
        >
          <img v-if="value.image_url" :alt="value.name" :src="value.image_url" />
          <span class="quick-search-results__link-text">
            <span class="quick-search-results__link-text-name">{{ value.name }}</span>
            <small>
              <i v-if="value.type_icon" class="ic icon ic--larger" :class="value.type_icon"></i>
              <span v-if="value.supplier_name">
                {{ value.supplier_name }}
              </span>
              <span v-if="value.visibility_icon" class="p-l-1">
                <i class="ic icon" :class="value.visibility_icon"></i>
              </span>
            </small>
          </span>
        </a>
      </li>
    </ul>
    <p v-if="result.path">
      <a
        :href="result.path"
        class="quick-search-results__total-link"
        @click="saveSearch(result.path)"
        @keyup.enter="saveSearch(result.path)"
      >
        {{ t(`vue_templates.quick_search.${result.type}`, { count: result.total }) }}
        <i class="ic icon ic-chevron-right"></i>
      </a>
    </p>
  </section>
</template>

<style lang="scss" scoped>
.quick-search-results__heading {
  color: $royal-blue;
  font-size: 1.5rem;
  padding: 2rem 2rem 0 1rem;
  font-weight: 600;
}

ul,
li {
  all: unset;
}

.quick-search-results__link {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    background-color: $platinum;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 4px;
  }
}

.quick-search-results__link-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: $black;
  width: 100%;
  overflow: hidden;
  &-name {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    color: #333333;
  }
}

.quick-search-results__total-link {
  display: flex;
  align-items: center;
  margin-left: auto;
  width: max-content;
  gap: 1rem;
  padding: 1rem;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    background-color: $platinum;
  }
}
</style>
