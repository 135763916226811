export default function useSideNavDynamicWidth() {
  const setDynamicWidth = () => {
    const sideNavigation = document.querySelector('.side-navigation');
    const vueRootElement = document.querySelector('.vue--sidebar-navigation');
    if (!sideNavigation || !vueRootElement) return;
    const width = sideNavigation.getBoundingClientRect().width;
    // Get the root width from the CSS variable and set the max dynamic width to 2x the root width
    const rootWidth = getComputedStyle(vueRootElement)?.getPropertyValue(
      '--side-navigation-width-expanded'
    );
    const maxWidth = parseFloat(rootWidth) * 2;
    const dynamicWidth = Math.min(width, maxWidth);
    (vueRootElement as HTMLElement)?.style?.setProperty(
      '--side-navigation-width-expanded-dynamic',
      `${dynamicWidth}px`
    );
  };

  return { setDynamicWidth };
}
