<script lang="ts">
export default {
  name: 'NavigationChevron',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};

export enum ChevronColor {
  LIGHT = 'light',
  BRANDED = 'branded',
  SUB_NAVIGATION = 'subNavigation',
}

export enum ChevronDirection {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ChevronSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
</script>

<script setup lang="ts">
import { computed, defineProps, withDefaults } from 'vue';

export interface NavigationChevronProps {
  direction?: ChevronDirection;
  theme?: ChevronColor;
  size?: ChevronSize;
}

const props = withDefaults(defineProps<NavigationChevronProps>(), {
  direction: ChevronDirection.RIGHT,
  theme: ChevronColor.LIGHT,
  size: ChevronSize.MEDIUM,
});

const colorClass = computed(() => {
  const colorMap = {
    [ChevronColor.LIGHT]: 'tw-bg-gray-600', // default
    [ChevronColor.BRANDED]: 'tw-bg-navigation-text',
    [ChevronColor.SUB_NAVIGATION]: 'tw-bg-sub-navigation-highlight',
  };
  return colorMap[props.theme];
});
</script>

<template>
  <div aria-hidden="true" class="chevron" :class="[direction, size, colorClass]"></div>
</template>

<style lang="scss" scoped>
.chevron {
  clip-path: polygon(40% 0%, 90% 50%, 40% 100%, 24% 85%, 60% 50%, 25% 15%);

  &.left {
    transform: rotate(180deg);
  }
  &.up {
    transform: rotate(-90deg);
  }
  &.down {
    transform: rotate(90deg);
  }

  &.small {
    width: 1rem;
    height: 1rem;
  }
  &.medium {
    width: 2rem;
    height: 2rem;
  }
  &.large {
    width: 3rem;
    height: 3rem;
  }
}
</style>
