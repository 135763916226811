<template>
  <button class="burger-menu__button" :class="{ 'burger-menu__button--open': open }">
    <div class="burger-menu__line"></div>
    <div class="burger-menu__line"></div>
    <div class="burger-menu__line"></div>
  </button>
</template>

<script lang="ts">
export default {
  name: 'BurgerMenu',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps } from 'vue';
defineProps<{
  open: boolean;
}>();
</script>

<style lang="scss">
.burger-menu__button {
  border: none;
  background: none;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  width: 30px;
}

.burger-menu__line {
  background-color: var(--navigation-text-color);
  height: 1px;
  width: 100%;
  transition: all 0.3s ease-out;
}

.burger-menu__button--open {
  .burger-menu__line {
    &:nth-child(1) {
      // transform: rotate(45deg) translate(5px, 5px);
      transform: rotate(45deg) translate(7px, 7px);
    }
    &:nth-child(2) {
      opacity: 0;
    }
    &:nth-child(3) {
      // transform: rotate(-45deg) translate(7px, -7px);
      transform: rotate(-45deg) translate(6px, -6px);
    }
  }
}
</style>
