<script lang="ts">
export default {
  name: 'SubNavigationPopover',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, defineEmits, ref, watch, withDefaults } from 'vue';
import PopoverMenu from '@/components/atoms/Popover/PopoverMenu/Index.vue';
import PopoverMenuItem from '@/components/atoms/Popover/PopoverMenuItem/Index.vue';
import SubNavigationPopoverMenuItem from './SubNavigationPopoverMenuItem.vue';
import BaseButton from '@/components/atoms/BaseButton/Index.vue';
import { PrimaryNavLink } from '@/json_schema_types/sidebar_navigation';
import { SubNavigationPopoverMenuItemType } from '@/types/components/organisms/SideNavigation';
import { useTestHelpers } from '@/composables/useTestHelpers';
import { isNil } from 'lodash';

interface NavigationPopoverProps {
  modelValue: boolean;
  navItem: PrimaryNavLink;
  customStyles?: Record<string, string>;
  maxHeight?: string;
  toggleElementSelector: HTMLElement | null;
}

const props = withDefaults(defineProps<NavigationPopoverProps>(), {
  maxHeight: '350px',
});

const emit = defineEmits(['update:modelValue']);

const { generateTestId } = useTestHelpers();

const selectedNavGroupIndex = ref<number | null>(null);

const clearSelectedNavGroupIndex = () => {
  selectedNavGroupIndex.value = null;
};

watch(
  () => props.modelValue,
  (newValue) => {
    if (!newValue) {
      clearSelectedNavGroupIndex();
    }
  }
);
</script>

<template>
  <PopoverMenu
    v-if="modelValue"
    class="subnav-popover"
    data-test-id="subnav-popover"
    max-width="200px"
    :max-height="props.maxHeight"
    position="right"
    :style="customStyles"
    :toggle-element-selector="toggleElementSelector"
    :model-value="modelValue"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <div class="subnav-popover__container tw-max-h-full tw-overflow-y-auto">
      <template v-if="isNil(selectedNavGroupIndex)">
        <PopoverMenuItem padding="0.25rem 2.5rem">
          <strong class="subnav-popover__header tw-py-2">{{ navItem.label }}</strong>
        </PopoverMenuItem>

        <template v-if="navItem.children && navItem.children.length === 1">
          <SubNavigationPopoverMenuItem
            v-for="(childNav, childNavIndex) in navItem.children[0].links"
            :key="`subnav-popover-menu-item-${childNavIndex}`"
            :item="childNav"
            :type="SubNavigationPopoverMenuItemType.Link"
          />
        </template>

        <template v-else-if="navItem.children && navItem.children.length > 1">
          <SubNavigationPopoverMenuItem
            v-for="(subnavGroup, subnavGroupIndex) in navItem.children"
            :key="`subnav-popover-menu-group-${subnavGroupIndex}`"
            :item="{ label: subnavGroup.group_title || '' }"
            :type="SubNavigationPopoverMenuItemType.Button"
            @click="selectedNavGroupIndex = subnavGroupIndex"
          />
        </template>
      </template>

      <template v-else-if="!isNil(selectedNavGroupIndex) && navItem.children">
        <div class="tw-px-5 tw-py-2">
          <BaseButton
            fa-prepend-icon="fa-solid fa-chevron-left"
            fa-icon-size="sm"
            variant="icon"
            theme="none"
            :data-test-id="
              generateTestId('subnav-popover-menu-group', `${selectedNavGroupIndex}`, 'back-button')
            "
            class="subnav-popover-menu-group__back-button"
            @click.prevent="clearSelectedNavGroupIndex"
          />
        </div>

        <PopoverMenuItem padding="0.25rem 2.5rem">
          <strong class="subnav-popover-menu-group__header">{{
            navItem.children[selectedNavGroupIndex].group_title
          }}</strong>
        </PopoverMenuItem>

        <SubNavigationPopoverMenuItem
          v-for="(childNav, childNavIndex) in navItem.children[selectedNavGroupIndex].links"
          :key="`subnav-popover-menu-group-${selectedNavGroupIndex}-item-${childNavIndex}`"
          :item="childNav"
          :type="SubNavigationPopoverMenuItemType.Link"
        />
      </template>
    </div>
  </PopoverMenu>
</template>

<style scoped lang="scss">
.subnav-popover {
  background-color: var(--navigation-color);
  color: var(--navigation-text-color);
}

.subnav-popover__container {
  &::-webkit-scrollbar {
    background-color: var(--navigation-color);
    width: 1.2rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--navigation-hover-color);
  }
}

.subnav-popover__header,
.subnav-popover-menu-group__header,
.subnav-popover-menu-group__back-button {
  color: var(--navigation-text-color);
}
</style>
