<script lang="ts">
export default {
  name: 'PopoverMenuDivider',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
// Remove if component does not use props.
import { defineProps } from 'vue';
defineProps<{ myProp?: string }>();
</script>

<template>
  <hr class="popover-menu-divider" />
</template>

<style lang="scss" scoped>
.popover-menu-divider {
  border: 0;
  display: block;
  width: 100%;
  background-color: $platinum;
  height: 1px;
  margin: 6px 0;
}
</style>
