<script setup lang="ts">
import { defineEmits, defineProps } from 'vue';
import { useI18n } from '@/composables/useI18n';

type Props = {
  recentSearch: {
    id: number;
    term: string;
    delete_path: string;
    ariaLabel: string;
    ariaDeleteLabel: string;
  };
  modelValue: string;
};

defineProps<Props>();
const emit = defineEmits(['update:modelValue', 'deleteRecentSearch']);
const { t } = useI18n();

const updateSearch = (term: string) => {
  emit('update:modelValue', term);
};
</script>

<template>
  <li
    :key="recentSearch.id"
    class="recent-search-results"
    :data-test-id="`recent-searches-item-${recentSearch.id}`"
  >
    <span :id="`recent-description-${recentSearch.id}`" class="visually-hidden">
      {{ recentSearch.ariaLabel }}
    </span>
    <a
      class="recent-search-results__link"
      href="#"
      role="button"
      :data-test-id="`recent-searches-link-${recentSearch.id}`"
      :aria-describedby="`recent-description-${recentSearch.id}`"
      @click.prevent.stop="updateSearch(recentSearch.term)"
    >
      {{ recentSearch.term }}
    </a>
    <span :id="`recent-remove-description-${recentSearch.id}`" class="visually-hidden">
      {{ recentSearch.ariaDeleteLabel }}
    </span>
    <a
      class="recent-search-results__remove"
      href="#"
      role="button"
      :data-test-id="`recent-searches-remove-${recentSearch.id}`"
      :aria-describedby="`recent-remove-description-${recentSearch.id}`"
      @click.prevent.stop="$emit('deleteRecentSearch', recentSearch)"
    >
      <i class="ic ic-error-cross"></i>
      <span class="visually-hidden">{{
        t('vue_templates.quick_search.aria_recent_search_remove')
      }}</span>
    </a>
  </li>
</template>

<style scoped lang="scss">
$hover-color: $platinum;

.recent-search-results {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.recent-search-results__link {
  flex: 1;
  border-radius: 4px;
  padding: 1rem;

  &:hover {
    background-color: $hover-color;
  }
}

.recent-search-results__remove {
  display: flex;
  justify-content: center;
  align-items: center;

  color: $link-color;
  border-radius: 4px;
  width: 3rem;
  height: 3rem;

  &:hover {
    background-color: $hover-color;
  }
}
</style>
