<script lang="ts">
export default {
  name: 'PopoverMenuLink',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps } from 'vue';
import { BaseMenuLink, PrimaryNavLink } from '@/json_schema_types/sidebar_navigation';
import { useTestHelpers } from '@/composables/useTestHelpers';
const { generateTestId } = useTestHelpers();
defineProps<{
  item: BaseMenuLink | PrimaryNavLink;
  openNewTab?: boolean;
  customStyles?: Record<string, string>;
  isNav?: boolean;
}>();
</script>

<template>
  <a
    class="popover-menu-item__link"
    :class="{ 'popover-menu-item__link--nav': isNav }"
    :style="customStyles"
    :data-test-id="generateTestId('popover-menu-link', item.label)"
    :href="item.url"
    :target="openNewTab ? '_blank' : '_self'"
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon
      v-if="item.icon"
      data-test-id="popover-menu-link-icon"
      :icon="`${item.icon} fa-regular`"
      size="lg"
      class="fa-fw"
    />
    <span>{{ item.label }}</span>
    <FontAwesomeIcon
      v-if="item.after_icon"
      data-test-id="popover-menu-link-after-icon"
      :icon="`${item.after_icon} fa-regular`"
      size="lg"
      class="fa-fw"
    />
  </a>
</template>

<style lang="scss" scoped>
.popover-menu-item__link {
  all: unset;
  color: $black;
  display: flex;
  flex: 1;
  width: 100%;
  gap: 1rem;
  align-items: center;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: $platinum;
  }

  &--nav:hover {
    background-color: var(--navigation-hover-color);
  }
}
</style>
